
import { Vue, Component, Watch } from 'vue-property-decorator';
import { UserModule } from '@/store/modules/user';
import { getJwtInfo } from '@/utils/cookies';
import { getUserInfo } from '@/api/user';
import { getRemainCount } from '@/api/order';
import { storageKey } from '@/enums/localStorage';
import { RoutingModule } from '@/store/modules/routing';
import { getNewAlarmCount } from '@/api/newAlarm';

@Component({
  components: {
  },
})

export default class extends Vue {
  mounted() {
    this.getNewAlarmCount();
    RoutingModule.setPoint('Mypage');
    this.getUserInfo();
  }

  private userInfo = {
    point: 0,
  };

  private newAlarmCount = 0;

  private getNewAlarmCount() {
    getNewAlarmCount().then((res) => {
      this.newAlarmCount = res.data;
    });
  }

  private getUserInfo() {
    getUserInfo().then((res) => {
      this.userInfo = res.data;
    });
  }

  private async logout() {
    await UserModule.LogOut();
    window.localStorage.removeItem(storageKey.pickUpPlace);
    this.$router.push({ name: 'Home' });
  }

  private handleWithdraw() {
    getRemainCount().then((res) => {
      if (res.data > 0) {
        this.$message.warning('픽업을 완료하지 않은 주문건이 있어 회원탈퇴가 불가합니다.');
      } else {
        window.localStorage.removeItem(storageKey.pickUpPlace);
        this.$router.push({ name: 'Withdrawal' });
      }
    });
  }
}
